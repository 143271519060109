import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IEventReportNegative } from "%%/labels/interfaces"

export class EventReportNegativeEn extends AbstractLocaleMessageObject implements IEventReportNegative {
  readonly aggregateClass = "Aggregation"

  readonly monthly = "Monthly"

  readonly daily = "Daily"

  readonly authoriModel = "Detection Model"

  readonly all = "All"

  readonly csvOutputLink = "CSV Output"

  readonly leftTableHeader = "Automatic Detection Results Report"

  readonly aaresultTotal = "Total Automatic Detection Results"

  readonly aaresultOk = "Automatic Detection OK"

  readonly aaresultNg = "Automatic Detection NG"

  readonly aaresultHold = "Automatic Detection HOLD"

  readonly aaresultReview = "Automatic Detection REVIEW"

  readonly negativedNo = "No Negative Registration"

  readonly negativedYes = "With Negative Registration"

  readonly csvFilenameDaily = "Daily Report CSV File"

  readonly csvFilenameMonthly = "Monthly Report CSV File"

  readonly captureRate = "Capture Rate"

  readonly captureRateDescription =
    "Capture Rate = (NG Count + REVIEW Negative Count) / (OK Negative Count + NG Count + REVIEW Negative Count)"
}
